import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {
  Configuration,
  FatModuleVariant,
  Id,
  ModuleBlueprint, ModuleCategory,
  ModuleVariant,
  RetailerOptions,
  WoodType
} from "@ess/jg-rule-executor";
import {takeUntil} from "rxjs/operators";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {MarkerPlacement} from "@src/app/model/marker-placement";

@Injectable({
  providedIn: 'root'
})
export class InitDataService implements OnDestroy {
  private _serviceDestroyed$: Subject<void> = new Subject();
  public missingVariants$: Subject<boolean> = new Subject(); // Note: obsolete bp's

  // just for convenience, the info is duplicate
  // allCatalogVarIds, currentFatModuleVariants, obsoleteFatModuleVariants,
  // allFatModuleVariants are derived from woodTypeFatVariants, obsoleteWoodTypeFatVariants and catalog
  // Contains variants which exist in the catalog: these are current and available (stock)
  public allCatalogVarIds: Id<ModuleVariant>[] = [];
  public allCatalogFatModuleVariants: FatModuleVariant[] = [];
  // Contains all fatVariants which are published, that means in or out of stock are both included.
  public publishedFatModuleVariants: FatModuleVariant[];
  // Contains all fatVariants which are available, that means out of stock and archived variants are NOT included.
  public availableFatModuleVariants: FatModuleVariant[];
  // Contains (fat)variants which are obsolete (archived), independent of their availability
  public obsoleteFatModuleVariants: FatModuleVariant[];
  // Contains all published fatVariants and all obsolete fatVariants which are in the configuration or the undo/history stack
  public inConfigAndPublishedModuleVariants: FatModuleVariant[];
  // Contains only the unavailable (fat)variants (current and obsolete)
  public unavailableFatModuleVariantIds: Id<ModuleVariant>[];

  public configuration: Configuration;
  public catalog: ConfiguratorCatalog;
  public currentRetailerOptions: RetailerOptions;
  public currentPreConfigurationCode: string;
  public currentPreConfigurationPrice$: Subject<number> = new Subject<number>();
  public currentScope: string;
  public authJwtToken: string;
  public woodTypePublishedFatVariants: Map<WoodType, FatModuleVariant[]> = new Map<WoodType, FatModuleVariant[]>();
  public woodTypeAvailableFatVariants: Map<WoodType, FatModuleVariant[]> = new Map<WoodType, FatModuleVariant[]>();
  // This only contains obsolete variants which are in the configuration or undo/history stack
  public obsoleteWoodTypeFatVariants: Map<WoodType, FatModuleVariant[]> = new Map<WoodType, FatModuleVariant[]>();
  public unavailableWoodTypeFatVariantIds: Map<WoodType, Id<FatModuleVariant>[]> = new Map<WoodType, Id<FatModuleVariant>[]>();
  public woodTypeCatalogs: Map<WoodType, ConfiguratorCatalog> = new Map<WoodType, ConfiguratorCatalog>();
  public woodTypeModuleVariantIds: Map<WoodType, Id<ModuleVariant>[]> = new Map<WoodType, Id<ModuleVariant>[]>();
  public currentWoodType: WoodType = null;
  public currentWoodTypeChanged$ = new BehaviorSubject<WoodType>(this.currentWoodType);
  public markerPlacement: MarkerPlacement;
  public forceMeasurementsOn: boolean = false;
  public showBoundingBoxes: boolean = false;
  public showMarkers: boolean = false;
  public showPlp: boolean = true; // TODO: Properly implement

  // Determines if data, like 3d models etc. should be preloaded. If not, they are loaded on the fly.
  public noDataPreload: boolean = true; // For now, the default is that we don't want to preload data
  public noRulesAndValidation: boolean = false;

  public arAllowed: { ios: boolean, android: boolean } = {ios: true, android: true};
  public openAr: boolean = false;
  public isIos: boolean = navigator.userAgent.match(/iPad|iPhone|iPod/) != null;
  public isAndroid: boolean = navigator.userAgent.match(/Android/) != null;

  constructor(
    stateService: ApplicationStateService,
    private _loggingService: LoggingService,
  ) {
    stateService.getState$()
      .pipe(takeUntil(this._serviceDestroyed$))
      .subscribe(s => {
        switch (s) {
          case ApplicationState.init: {
            // reset all data
            this._resetData();
            break;
          }
          default: {
            // do nothing
            break;
          }
        }
      });
  }

  /**
   * Helper to reset all data.
   */
  private _resetData(): void {
    // eslint-disable-next-line no-console
    this._loggingService.debug("InitdataService::_resetData()");
    this.publishedFatModuleVariants = [];
    this.availableFatModuleVariants = [];
    this.allCatalogVarIds = [];
    this.allCatalogFatModuleVariants = [];
    this.obsoleteFatModuleVariants = [];
    this.inConfigAndPublishedModuleVariants = [];
    this.unavailableFatModuleVariantIds = [];
    this.configuration = undefined;
    this.currentPreConfigurationCode = undefined;
    this.catalog = undefined;
    this.currentWoodType = undefined;

    this.woodTypePublishedFatVariants = new Map<WoodType, FatModuleVariant[]>();
    this.woodTypeAvailableFatVariants = new Map<WoodType, FatModuleVariant[]>();
    this.woodTypeCatalogs = new Map<WoodType, ConfiguratorCatalog>();

    this.missingVariants$.next(false);
    this.currentPreConfigurationPrice$.next(0);
    // Do not reset scope!
  }

  switchWoodType(woodType: WoodType): void {
    if (this.currentWoodType !== woodType) {
      this.publishedFatModuleVariants = this.woodTypePublishedFatVariants.get(woodType);
      this.availableFatModuleVariants = this.woodTypeAvailableFatVariants.get(woodType);
      this.obsoleteFatModuleVariants = this.obsoleteWoodTypeFatVariants.get(woodType);
      this.unavailableFatModuleVariantIds = this.unavailableWoodTypeFatVariantIds.get(woodType);
      this.inConfigAndPublishedModuleVariants = [...this.publishedFatModuleVariants, ...this.obsoleteFatModuleVariants];
      this.catalog = this.woodTypeCatalogs.get(woodType);
      this.allCatalogVarIds = Array.from(new Set(this.catalog.categories.reduce(
        (prev, cur) =>
          [...prev, ...cur.moduleCategories.reduce((prevIn, curIn) => [...prevIn, ...curIn.moduleVariantIds], [])],
        []
      )));
      this.allCatalogFatModuleVariants = this.allCatalogVarIds.map(id => this.inConfigAndPublishedModuleVariants.find(v => v.variant.id === id));

      this.currentWoodType = woodType;
      this.currentWoodTypeChanged$.next(woodType);
    }
  }

  allVariantsForWoodType(woodType: WoodType): FatModuleVariant[] {
    const currentFatModuleVariants = this.woodTypePublishedFatVariants.get(woodType) || [];
    const obsoleteFatModuleVariants = this.obsoleteWoodTypeFatVariants.get(woodType) || [];
    return [...currentFatModuleVariants, ...obsoleteFatModuleVariants];
  }

  getBlueprintCategory(blueprint: ModuleBlueprint): ModuleCategory {
    return this.catalog?.categories
      ?.map(c => c.moduleCategories).flat()
      .find(c => c.reference === blueprint.categoryReference);
  }

  /**
   * On destroy close subscriptions
   */
  public ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
  }
}
