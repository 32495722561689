import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {combineLatest, Subject} from "rxjs";
import {takeUntil, withLatestFrom} from "rxjs/operators";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ViewMode} from '@src/app/model/view-mode';
import {ApplicationStateService} from '@src/app/services/applicationstate/application-state.service';
import {ApplicationState} from '@src/app/model/applicationstate';
import {ModalService} from "@src/app/services/modal/modal.service";
import {TranslateService} from "@ngx-translate/core";
import {DiscountedPriceAppearance} from "@ess/jg-rule-executor/dist/src/domain/discounted-price-appearance";
import {GaTaggable} from "@src/app/helpers/ga-taggable";
import {GoogleAnalyticsEventDirective} from "@src/app/directives/google-analytics-event.directive";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends GaTaggable implements OnInit, OnDestroy {

  @Input() loadingFinished: boolean = false;

  public viewMode: ViewMode = ViewMode.loading;
  public configValid: boolean = false; // start on false to disable while loading not complete
  public configAvailable: boolean = false;
  public allInStock: boolean = true;
  public woodTypeAvailable: boolean = true;
  public priceToDisplay: string;
  public discountedPriceToDisplay: string;
  public discountedPriceAppearance: { [p: string]: any };
  public displayDiscountedPrice: boolean;
  public extraText: string = 'app.footer.delivery_placeholder';

  public priceText: string = '';
  public priceTextDefined: boolean = true;

  public inPlp: boolean = false;
  public plpAvailable: boolean = false;

  public plpDisabled: boolean = false;

  public editMode: boolean = false;
  public previewMode: boolean = false;

  public saveButton: boolean = false;

  public rulesAreLoaded: boolean = false;

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _interactionService: InteractionService,
    public dataService: InitDataService,
    private _priceCalculatorService: PriceCalculatorService,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _applicationStateService: ApplicationStateService
  ) {
    super();
    combineLatest([
      _applicationStateService.getState$(),
      this._interactionService.getViewMode$()
    ]).pipe(
      takeUntil(this._componentDestroyed$))
      .subscribe(([appInit, viewMode]) => {
        this.plpDisabled = (appInit <= ApplicationState.rulesAreLoaded && viewMode === ViewMode.edit)
        if ((appInit === ApplicationState.appInitialized && viewMode === ViewMode.edit) || viewMode !== ViewMode.loading) {
          this.viewMode = viewMode;
          this.plpAvailable = this.dataService.currentRetailerOptions?.preconfigurations.length > 1;
        }
        if (appInit === ApplicationState.rulesAreLoaded) {
          this.rulesAreLoaded = true;
        } else if (appInit === ApplicationState.init) {
          this.rulesAreLoaded = false;
        }
        if (appInit === ApplicationState.translationsAreLoaded) {
          this.priceText = this._translateService.instant('app.footer.price_text');
          this.priceTextDefined = !!this.priceText && this.priceText !== 'app.footer.price_text';
        }
        this.inPlp = this.viewMode === ViewMode.plp;
        this.editMode = this.viewMode === ViewMode.edit;
        this.previewMode = this.viewMode === ViewMode.preview;
        this.saveButton = this.dataService.currentRetailerOptions?.configuratorOptions?.saveButton;
      });

  }

  ngOnInit() {
    // Receive price updates and change the display price accordingly
    this.dataService.currentPreConfigurationPrice$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(newPrice => {
        if (newPrice) {
          this.priceToDisplay = `${this._priceCalculatorService.priceFormatter(newPrice)}`;
        } else {
          this.priceToDisplay = undefined;
        }
      });

    combineLatest(
      [
        this._priceCalculatorService.currentPrice$(),
        this._priceCalculatorService.priceWithoutDiscount$()
      ]
    ).pipe(takeUntil(this._componentDestroyed$))
      .subscribe(newPrice => {
        this.priceToDisplay = `${this._priceCalculatorService.priceFormatter(newPrice[0])}`;
        this.discountedPriceToDisplay = `${this._priceCalculatorService.priceFormatter(newPrice[1])}`;
        if (newPrice[0] < newPrice[1]) {
          this.discountedPriceAppearance = this.discountPriceStyling(this.dataService.currentRetailerOptions?.configuratorOptions?.discountedPriceAppearance);
          this.displayDiscountedPrice = !!this.discountedPriceToDisplay && !!this.discountedPriceAppearance;
        } else
          this.displayDiscountedPrice = false
      });


    this._interactionService.getConfigurationChanged$()
      .pipe(takeUntil(this._componentDestroyed$),
        withLatestFrom(this.dataService.missingVariants$))
      .subscribe(([c, r]) => {
        const woodTypeAvailable = this.dataService.currentRetailerOptions.woodTypesAvailable
          .filter(w => w.available === true)
          .map(w => w.woodType).includes(c.configurationPlacement.woodType);
        if (r || !woodTypeAvailable) {
          this.extraText = 'app.footer.out_of_stock';
          this.allInStock = false;
        } else {
          this.extraText = 'app.footer.delivery_placeholder';
          this.allInStock = true;
        }
      });

    this._interactionService.getAvailabilityChanged$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(available =>
        this.configAvailable = available);

    this._interactionService.getValidityChanged$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(valid =>
        this.configValid = valid);

  }


  /**
   * Function that takes the user back to the edit view
   */
  public clickBack(): void {
    if (this.editMode) {
      this._modalService.open('plp-modal', {config: this.dataService.configuration})
    } else this._interactionService.setViewMode(ViewMode.edit);
  }

  /**
   * Function that saves the configuration.
   * For now, it posts an alert to the user to inform.
   */
  public clickSave(): void {
    this._interactionService.saveCurrentConfiguration();
  }

  /**
   * Function that takes the user to the result view or adds the configuration to the cart in an external sales website depending on the viewMode
   */
  public clickNext(): void {
    switch (this.viewMode) {
      case 1: {
        this._interactionService.setViewMode(ViewMode.edit);
        this.gTagTimedClick('footer.next');
        break;
      }
      case 2: {
        this._interactionService.setViewMode(ViewMode.preview);
        this.gTagTimedClick('footer.next');
        break;
      }
      default: {
        this._interactionService.placeConfigurationOrder$();
        this.gTagTimedClick('footer.cart');
        break;
      }
    }
  }

  public discountPriceStyling(appearance: DiscountedPriceAppearance): { [p: string]: string | number } {
    return {
      'text-decoration': appearance?.strikeThrough ? 'line-through' : 'none',  // Apply strikethrough conditionally
      'font-size.px': appearance?.textSize || undefined,  // Use undefined if no value to avoid invalid empty styles
      'color': appearance?.color || '#000000'  // Default to black if color is not specified
    };
  }

  /**
   * OnDestroy close subscriptions
   */
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}

