// declare gtag as global function as it is defined in the index.html asynchronously
declare var gtag: Function;

/**
 * A class you can extend for gtag functionality
 */
export abstract class GaTaggable {
  readonly _isGTagAvailable: boolean = false;

  constructor() {
    this._isGTagAvailable = typeof gtag === 'function';
  }

  public gTagClick(logObject: object){
    if(this._isGTagAvailable) {
      gtag('event', 'click', logObject);
    }
  }

  public gTagTimedClick(gaTag: string) {
    if (this._isGTagAvailable) {
      const date = new Date();
      const time = date.toLocaleDateString() + '_' + date.toLocaleTimeString()
      // Send the Google Analytics event when the element is clicked
      this.gTagClick({
        'GA-tag': gaTag,
        'dateTime': time
      });
    } else {
      console.warn('Google Analytics is not initialized');
    }
  }
}
