import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {first, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {
  Attribute,
  Configuration,
  FatModuleVariant,
  id,
  Id,
  ModulePlacement,
  ModuleVariant,
} from '@ess/jg-rule-executor';
import {CatalogManagementService} from '@src/app/services/catalog-management/catalog-management.service';
import {InteractionService} from '@src/app/services/interaction/interaction.service';
import {RenderService} from '@src/app/services/render/render.service';
import {InitDataService} from '@src/app/services/data/init-data.service';
import {Icon, iconSource} from '@src/app/library/components/icon';
import {FatFlatCatalogCategory} from '@src/app/model/fat-flat-catalog';
import {ConfiguratorCatalog, IconValue} from '@src/app/model/configurator-catalog';
import {LoggingService} from '@src/app/services/logging/logging.service';
import {PriceCalculatorService} from '@src/app/services/price-calculator/price-calculator.service';
import {ApplicationStateService} from '@src/app/services/applicationstate/application-state.service';
import {ApplicationState} from '@src/app/model/applicationstate';
import {ViewMode} from '@src/app/model/view-mode';
import {GaTaggable} from '@src/app/helpers/ga-taggable';
import {AttachmentPointInfo} from "@src/app/model/attachment-point-info";

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent extends GaTaggable implements OnInit, OnDestroy {

  @Input() loadingFinished: boolean = false;

  public catalogCategories: FatFlatCatalogCategory[] = [];
  public catalogAttributesList: {
    key: string,
    values: { key: string, values: { key: string, value: IconValue }[] }[]
  }[] = [];
  public showCategories: boolean = false;
  public showEditCustomizable: boolean = false;
  public showDeleteModule: boolean = false;
  public originModule: boolean = false;
  public latestSelected: Id<ModuleVariant>[] = [];
  public trashIconSrc: string = iconSource(Icon.trash);
  public filterSetting: Map<Id<Attribute>, string> = new Map();
  public viewMode: ViewMode = ViewMode.edit;
  public plpMode: boolean = false;

  private _componentDestroyed$: Subject<void> = new Subject();
  private _selectionPlacementId: Id<ModulePlacement>;

  // Logs a warning if certain events take longer than this time
  private readonly _clickTimeCutoffMs: number = 1000;

  public installationServiceAvailable: boolean = this._priceCalculatorService.installationServiceAvailableForRetailer();

  constructor(
    private _catalogManagementService: CatalogManagementService,
    private _dataService: InitDataService,
    private _interactionService: InteractionService,
    private _renderService: RenderService,
    private _loggingService: LoggingService,
    private _priceCalculatorService: PriceCalculatorService,
    stateService: ApplicationStateService,
  ) {
    super();

    stateService.getState$()
      .pipe(
        filter(t => t === ApplicationState.currentScopeAndRetailerAreLoaded),
        first()
      )
      .subscribe(() => {
        this.installationServiceAvailable = this._priceCalculatorService.installationServiceAvailableForRetailer();
      });
  }

  public ngOnInit(): void {
    this._renderService.getViewMode$().subscribe(v => {
      this.viewMode = v;
      this.plpMode = this.viewMode === ViewMode.plp;
    });

    this._catalogManagementService.filteredCatalog$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(c => {
        // If the catalog is defined, we show it, otherwise assume no attachment point selected
        if (c) {
          this.catalogCategories = c.categories;
        } else {
          this.showCategories = false;
          // Reset categories just in case
          this.catalogCategories = [];
        }
      });

    this._catalogManagementService.catalog$
      .pipe(takeUntil(this._componentDestroyed$), filter(c => !!c))
      .subscribe(c => {
        this._handleCatalogUpdate(c);
      });

    // waiting for user to click on an attachmentPoint
    this._renderService.clickedAttachmentPoint
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(result => {
        this._handleAttachmentPointClick(result.info, result.config, result.logClickGA, result.scrollIntoView);
      });

    // waiting for user to click on an editPoint
    this._renderService.clickedEditPoint
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(result => {
        this._handleEditPointClick(result.info, result.config, result.logClickGA);
      });
  }

  /**
   * Handle catalog update, making a new list of attributes for the selected placement
   */
  private _handleCatalogUpdate(catalog: ConfiguratorCatalog): void {
    // obtain variant of currently selected placement
    const placement = this._renderService.getCurrentConfiguration().configurationPlacement.placement(this._selectionPlacementId);
    const variant = this._dataService.inConfigAndPublishedModuleVariants.find((fv) => fv.variant.id === placement.variantId);

    this.catalogAttributesList = []; // first empty the array, to prevent double values.

    // filterSettings contain 'menu' settings per module category, so pick the one related to the selected placement.
    const setting = catalog.filterSettings.get(variant.blueprint.categoryReference);
    const filteredVariants = catalog.filteredVariants.get(variant.blueprint.categoryReference);

    const catalogAttributes: { key: string, values: { key: string, value: IconValue }[] }[] = [];

    if (setting && filteredVariants) {
      setting.attributes.values.forEach((v, k) => {
        // Find the icons for which one or more variants exist
        const varIdsForAttr = filteredVariants.filter(fv => fv.attributeId === k && v.valueIcons.values.has(fv.value));
        const filteredIcons = Array.from(v.valueIcons.values)
          .filter(([key, value]) => varIdsForAttr.find(exval => exval.value === key));
        // for every attribute create an object with the key of the attribute and all corresponding valueIcons
        if (filteredIcons.length > 0) {
          catalogAttributes.push({ // html can't handle Maps, so it will be converted to a raw object array.
            key: k,
            values: Array.from(filteredIcons, ([key, value]) => (
              {key, value}
            )),
          });
        }
      });
    }
    if (catalogAttributes.length > 0) {
      this.catalogAttributesList.push({key: variant.blueprint.categoryReference, values: catalogAttributes});
    }
  }

  /**
   * Click handler for attachment point
   */
  private _handleAttachmentPointClick(
    info: AttachmentPointInfo,
    config: Configuration,
    logClickGA: boolean,
    scrollIntoView?: Id<ModuleVariant>
  ): void {
    const time = performance.now();
    this.showEditCustomizable = false;

    if (info && config) {
      const selectionPlacement: ModulePlacement = config.configurationPlacement.placement(info.placementId);
      const xNeighbours = config.configurationPlacement.getXNeighboursWithY(selectionPlacement);

      // originModule will be set to true when the current placement is the origin placement
      this.originModule = config.configurationPlacement.getOriginPlacement().id === selectionPlacement.id;

      if (info.childIds?.length > 0) {
        this.latestSelected = xNeighbours.filter(x => info.childIds.includes(x.x)).map(x => x.targetPlacement.variantId);
      } else {
        this.latestSelected = xNeighbours.filter(x => x.x === info.xAttachmentId).map(x => x.targetPlacement.variantId);
      }

      this.showCategories = true;
      this.showDeleteModule = !info.childIds;
      this._refreshSceneAfterStartup(config);
    } else {
      this.latestSelected = [];
    }

    this._catalogManagementService.filteredCatalog$
      .pipe(first(), takeUntil(this._componentDestroyed$))
      .subscribe(async catalog => {
        const scrollToCategory = scrollIntoView ? catalog.getCategoryForVariant(scrollIntoView)?.title : undefined;
        if (scrollToCategory) {
          await new Promise(r => setTimeout(r, 100)); // wait for the view to update
          const searchId = scrollToCategory + '_id';
          const element = document.getElementById(searchId);
          if (element) {
            element.scrollIntoView({behavior: 'smooth'});
          }
        }
      });
    this._catalogManagementService.filterCatalog(info, config);

    // handle GaTag and timing
    const duration = performance.now() - time;
    if (this._isGTagAvailable && logClickGA) {
      this._sendGATagWithDuration(
        `attachmentPoint.${info.placementId}.${info.xAttachmentId ?? info.childIds.join('+')}`,
        duration.toFixed(0),
      );
    }
    if (duration > this._clickTimeCutoffMs) {
      this._loggingService.warn('Clicking of attachment point took long', [`Duration: ${duration.toFixed(0)}ms`]);
    }
  }

  /**
   * Click handler for edit point
   */
  private _handleEditPointClick(
    info: AttachmentPointInfo,
    config: Configuration,
    logClickGA: boolean
  ): void {
    const time = performance.now();

    this.showEditCustomizable = true;
    this.showCategories = false;
    this.originModule = false;

    if (info?.placementId && config) {
      const selectionPlacement: ModulePlacement = config.configurationPlacement.placement(info.placementId);
      this._selectionPlacementId = selectionPlacement.id;
      const selectionVariant: FatModuleVariant =
        this._dataService.inConfigAndPublishedModuleVariants.find(v => v.variant.id === selectionPlacement.variantId);
      this.filterSetting = new Map<Id<Attribute>, string>();
      selectionVariant?.variant?.attributeValues?.forEach((v, k) => {
        this.filterSetting.set(k, v);
      });

      // originModule will be set to true when the current placement is the origin placement
      this.originModule = config.configurationPlacement.getOriginPlacement().id === selectionPlacement.id;
      this._refreshSceneAfterStartup(config);
    }

    // only show button if delete will result in success
    const expectedResult = this._interactionService
      .getMainModuleRemoveResult(info.placementId, config.configurationPlacement, false);

    // We can delete if the placement check was a success, and we are not removing the origin
    this.showDeleteModule = config.configurationPlacement.originPlacement !== info.placementId && expectedResult.type === 'success';
    this._catalogManagementService.getCatalog();

    const duration = performance.now() - time;
    if (this._isGTagAvailable && logClickGA) {
      this._sendGATagWithDuration(`editPoint.${info.placementId}`, duration.toFixed(0));
    }
    if (duration > this._clickTimeCutoffMs) {
      this._loggingService.warn(`Clicking of edit point took ${duration.toFixed(0)}ms`);
    }
  }

  /**
   * Click handler of the specific itemId called
   */
  public clickCatalogItem(itemId: Id<ModuleVariant>): void {
    this._renderService.spinnerOnSubject$.next();
    const time = performance.now();
    this._interactionService.addVariantToConfiguration(itemId);

    // handle GaTag and timing
    const duration = performance.now() - time;
    if (this._isGTagAvailable) {
      const itemFatVar = this._dataService.allCatalogFatModuleVariants.find(fv => fv.variant.id === itemId);
      this._sendGATagWithDuration(`catalog.variant.${itemFatVar.variant.name}`, duration.toFixed(0));
    }
    if (duration > this._clickTimeCutoffMs) {
      this._loggingService.warn('Handling item catalog click took long', [`Duration: ${duration.toFixed(0)}ms`]);
    }
    this._renderService.spinnerOffSubject$.next();
  }

  /**
   * Click handler for attribute
   */
  public clickCatalogAttribute(attributeIdString: string, value: string): void {
    this._renderService.spinnerOnSubject$.next();
    const time = performance.now();
    const key = id<Attribute>(attributeIdString);
    this.filterSetting.set(id<Attribute>(attributeIdString), value);
    this._interactionService.replaceVariant(this._selectionPlacementId, this.filterSetting, key);

    const duration = performance.now() - time;
    if (this._isGTagAvailable) {
      const attributeName = this.catalogAttributesList.find(
        att => att.values.some(kv => kv.key === attributeIdString),
      )?.key;
      this._sendGATagWithDuration(`catalog.edit-tower.${attributeName}.${value}`, duration.toFixed(0));
    }
    if (duration > this._clickTimeCutoffMs) {
      this._loggingService.warn('Handling item catalog click took long', [`Duration: ${duration.toFixed(0)}ms`]);
    }
    this._renderService.spinnerOffSubject$.next();
  }

  /**
   * Click handler for removing a variant
   */
  public clickNoModuleItem(): void {
    const time = performance.now();

    if (!this.showEditCustomizable) {
      this._interactionService.removeSelectedItemFromConfiguration(this.removeCallback.bind(this));
    } else {
      this._interactionService.removeMainModuleFromConfiguration(this.removeCallback.bind(this));
    }

    const duration = performance.now() - time;
    if (this._isGTagAvailable) {
      this._sendGATagWithDuration('catalog.remove-button', duration.toFixed(0));
    }
  }

  public removeCallback(success: boolean): void {
    if (success) {
      this.catalogCategories = [];
      this.showEditCustomizable = false;
      this.latestSelected = [];
    }
  }

  // reloads the configuration to set the button colors
  private _refreshSceneAfterStartup(config: Configuration): void {
    if (this._renderService.startup) {
      this._renderService.startup = false;
      void this._renderService.loadConfigurationToScene(config, false);
    }
  }

  private _sendGATagWithDuration(tagName: string, duration: string) {
    const date = new Date();
    this.gTagClick({
      'GA-tag': tagName,
      'dateTime': date.toLocaleDateString() + '_' + date.toLocaleTimeString(),
      'clickDurationMilliseconds': duration,
    });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  protected readonly Attribute = Attribute;
  protected readonly id = id;
}
